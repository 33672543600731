<template>
  <div>
    <v-btn
      class="activate-button"
      icon
      @click.prevent="dialog = true"
      style="z-index: 5"
    >
      <v-icon color="success">restore</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" max-width="800px">
      <v-card>
        <v-card-title class="primary">
          <span class="headline white--text">Activate Animal</span>
        </v-card-title>
        <v-card-text>
          Do you really want to activate "{{animal.title}}"?
        </v-card-text>
        <v-card-actions class="mb-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" @click.native="del()" :loading="loading" class="mb-3">Activate</v-btn>
          <v-btn color="error" flat @click.native="dialog = false" class="mb-3">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  // import { VueEditor } from 'vue2-editor';
  import { mapActions } from 'vuex';


  export default {
    name: 'DlgAnimalsDelete',
    components: {
      // VueEditor,
    },
    data() {
      return {
        dialog: false,
        loading: false,
      };
    },
    props: ['animal'],
    methods: {
      ...mapActions({
        animalUpdate: 'admin/animals/update',
        animalRead: 'admin/animals/read',
      }),
      del() {
        this.loading = true;
        this.animalUpdate({ ...this.animal, deleted: false })
          .then((res) => {
            this.loading = false;
            if (res) {
              this.animalRead();
              this.dialog = false;
            }
          });
      },
    },
  };
</script>

<style scoped>
</style>
