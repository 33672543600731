import { render, staticRenderFns } from "./DlgAnimalsActivate.vue?vue&type=template&id=10b15f62&scoped=true"
import script from "./DlgAnimalsActivate.vue?vue&type=script&lang=js"
export * from "./DlgAnimalsActivate.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10b15f62",
  null
  
)

export default component.exports